import React from 'react';
import './HeaderLines.css';

const HeaderLines = () => {
  const divRef = React.useRef(null); // Create a ref for the div element
  React.useEffect(() => {
    const handleResize = () => {
      const divElement = divRef.current; // Get the div element from the ref
      const divHeight = divElement.clientHeight; // Get the height of the div element

      const lineHeight = 20; // Line height in pixels
      const numberOfLines = Math.floor(divHeight / lineHeight); // Calculate the number of lines

      const lineNumbers = Array.from({ length: numberOfLines }, (_, i) => i + 1); // Create an array of line numbers

      // Update the state with the new line numbers
      setLineNumbers(lineNumbers);
    };

    handleResize();

    window.addEventListener('resize', handleResize); // Add event listener for window resize

    return () => {
      window.removeEventListener('resize', handleResize); // Remove event listener on component unmount
    };
  }, []);

  const [lineNumbers, setLineNumbers] = React.useState([]); // State to store the line numbers

  return (
    <div className="code-editor" ref={divRef}>
      <div className="line-numbers">
        {lineNumbers.map(lineNumber => (
          <div key={lineNumber} className="line-number">{lineNumber}</div>
        ))}
      </div>
      <div className="code-lines">
        {lineNumbers.map(lineNumber => (
          <div key={lineNumber} className="code-line">Code line {lineNumber}</div>
        ))}
      </div>
    </div>
  );
};

export default HeaderLines;